import { createGlobalStyle } from "styled-components"
import * as ThemeSettings from "../styles/theme"
// ModernEra-Bold
// Gerstner-ProgrammMedium
import ModernEraBoldEot from "../fonts/ModernEra-Bold.eot"
import ModernEraBoldWoff from "../fonts/ModernEra-Bold.woff"
import ModernEraBoldWoff2 from "../fonts/ModernEra-Bold.woff2"
import ModernEraRegularEot from "../fonts/ModernEra-Regular.eot"
import ModernEraRegularWoff from "../fonts/ModernEra-Regular.woff"
import ModernEraRegularWoff2 from "../fonts/ModernEra-Regular.woff2"

export const GlobalReset = createGlobalStyle`

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

`

export const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: "ModernEra Regular";
    src: local("ModernEra Regular"), url("${ModernEraRegularEot}");
    src: url("${ModernEraRegularEot}?#iefix") format("embedded-opentype"),
        url("${ModernEraRegularWoff2}") format("woff2"),
        url("${ModernEraRegularWoff}") format("woff");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "ModernEra Bold";
    src: local("ModernEra Bold"), url("${ModernEraBoldEot}");
    src: url("${ModernEraBoldEot}?#iefix") format("embedded-opentype"),
        url("${ModernEraBoldWoff2}") format("woff2"),
        url("${ModernEraBoldWoff}") format("woff");
    font-weight: bold;
    font-style: normal;
  }

  *, html, body {
    margin: 0;
    padding: 0;
  }

  body {
    font-family: "ModernEra Regular", "Helvetica Neue", Helvetica, Arial, -apple-system, sans-serif;
    font-weight: normal;
    font-size: 20px;
    line-height: 1.3;
    /* letter-spacing: 0.8px; */
    background-color: ${ThemeSettings.bodyBackgroundColor};
    color: ${ThemeSettings.mainColor};
  }

  a {
    color: ${ThemeSettings.mainColor};
    text-decoration: none;
    transition: transform 0.5s ease;
  }

  ::selection {
    background: ${ThemeSettings.colors.highlight};
    color: #000;
    text-shadow: none;
  }

`