import theme from "styled-theming"

export const bodyBackgroundColor = theme("mode", {
  light: "#fff",
  dark: "#000"
})

export const mainColor = theme("mode", {
  light: "#000",
  dark: "#fff"
})

export const colors = {
  lightGrey: "#848484",
  blue: "#0062ff",
  highlight: "#A9A9A9"
}

export const metrics = {
  headerHeightDesktop: "140px",
  pageDesktop: "2vw",
  pageMobile: "6vw"
}