import React from "react"
import { GlobalReset, GlobalStyle } from "../styles/global"
import SEO from "../seo"

const LayoutDefault = props => {
  return (
    <>
      <GlobalReset />
      <GlobalStyle />
      <SEO />
      { props.children }
    </>
  )
}

export default LayoutDefault