import React from "react"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

const SEO = ({ data }) => {

  const content = data.prismic.seo
  const browserTitle = content.browser_title[0].text
  const siteTitle = content.site_title[0].text
  const description = content.site_description[0].text
  const shareImage = content.social_share_image.url
  const twitterImage = content.twitter_share_image.url

  return (
    <Helmet>
      <title>{ browserTitle }</title>

      <meta name="description" content={ description } />
      <meta itemprop="image" content={ shareImage } />

      <meta
        property="og:url"
        content="https://www.mister.studio/"
      />
      <meta
        property="og:type"
        content="website"
      />
      <meta
        property="og:title"
        content={ siteTitle }
      />
      <meta
        property="og:description"
        content={ description }
      />
      <meta
        property="og:image"
        content={ twitterImage }
      />

      <meta
        name="twitter:card"
        content="summary_large_image"
      />
      <meta
        name="twitter:title"
        content="Title"
      />
      <meta
        name="twitter:site"
      />
      <meta
        name="twitter:description"
        content={ description }
      />
      <meta
        name="twitter:image"
        content={ twitterImage }
      />

    </Helmet>
  )
}

export default props => (
  <StaticQuery
    query={ graphql`
      query MetaQuery {
        prismic {
          seo(lang: "en-us", uid: "seo") {
            browser_title
            site_title
            site_description
            social_share_image
            twitter_share_image
          }
        }
      }  
    ` }
    render={ data => <SEO data={ data } { ...props } /> }
  />
)
